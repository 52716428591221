
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Code"
              label-for="basicInput"
            >

             <validation-provider
              #default="{ errors }"
              name="Code"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.code"
                :plaintext="readOnly"
                placeholder="Code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Type de remise"
              label-for="Type de remise"
            >
             <validation-provider
              #default="{ errors }"
              name="Type de remise"
              rules="required"
            >
              <v-select
                v-model="form.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
               
                :state="errors.length > 0 ? false:null"
                :options="typeChoices"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col> -->
         
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
        
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Montant (%)"
              label-for="Prix"
            >
            
             <validation-provider
              #default="{ errors }"
              name="Montant"
              rules="required"
            >
              <b-form-input
              required
                id="Prix"
                v-model="form.amount"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Prix"
                :state="errors.length > 0 ? false:null"
              />
               <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Actif"
              label-for="Actif"
            >
              <b-form-checkbox
                v-model="form.active"
                
                class="custom-control-primary"
              >
                Actif ?
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Produits"
              label-for="Type de verre"
            >
            
              <v-select
                v-model="form.products"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
               
                multiple
                :options="productsChoices"
              />
              
            </b-form-group>
          </b-col>

          <!-- <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Active la livraison gratuite"
              label-for="Actif"
            >
              <b-form-checkbox
                v-model="form.free_shipping"
                
                class="custom-control-primary"
              >
                Active la livraison gratuite
              </b-form-checkbox>
            </b-form-group>
          </b-col> -->

          <!-- <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Date de fin"
              label-for="Date de fin"
            >
              <b-form-datepicker
                id="example-datepicker"
                v-model="form.end_date"
                class="mb-1"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Montant minimum de commande (€)"
              label-for="Prix"
            >
              <b-form-input
                id="Prix"
                v-model="form.minimum_spend"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Montant minimum"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Montant maximum de commande (€)"
              label-for="Prix"
            >
              <b-form-input
                id="Prix"
                v-model="form.maximum_spend"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Montant maximum"
              />
            </b-form-group>
          </b-col>

           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Usage individuel seulement (non cumulable)"
              label-for="Actif"
            >
              <b-form-checkbox
                v-model="form.individual_use"
                
                class="custom-control-primary"
              >
                Usage individuel seulement (non cumulable)
              </b-form-checkbox>
            </b-form-group>
          </b-col>

           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Exclure les produits soldés"
              label-for="Actif"
            >
              <b-form-checkbox
                v-model="form.exclude_sale_items"
                
                class="custom-control-primary"
              >
                Exclure les produits soldés
              </b-form-checkbox>
            </b-form-group>
          </b-col>

         

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Catégories de produits"
              label-for="Type de verre"
            >
            
              <v-select
                v-model="form.product_categories"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
               
                multiple
                :options="productsCategoriesChoices"
              />
              
            </b-form-group>
          </b-col> -->

           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Limite d'usage (général)"
              label-for="Prix"
            >
              <b-form-input
                id="Prix"
                v-model="form.usage_limit"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Limite d'usage (général)"
              />
            </b-form-group>
          </b-col>

           <!-- <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Limite par utilisateur"
              label-for="Prix"
            >
              <b-form-input
                id="Prix"
                v-model="form.user_limit"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Limite par utilisateur"
              />
            </b-form-group>
          </b-col>


           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Emails autorisés (séparés par une ,)"
              label-for="basicInput"
            >

              <b-form-input
              
                id="basicInput"
                v-model="form.allowed_emails"
                :plaintext="readOnly"
                placeholder="Emails autorisés"
                
              />
             
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-form>
      </validation-observer>

    </b-card>

      <b-button v-if="edition==false"
     @click="createPromotion"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="editPromotion"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BForm, BRow, BOverlay,BFormDatepicker, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils} from '@simb2s/senseye-sdk'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'PromotionForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormDatepicker
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          loading:true,
          products:[],
          productsChoices:[],
          productsCategoriesChoices: [
            {
              title: 'Collection',
              value: 'collection',
            },
            {
              title: 'Collaboration',
              value: 'collaboration',
            }
          ],
          typeChoices: [
            {
              label: 'Remise fixe (en €)',
              value: 'fixed',
            },
            {
              label: 'Pourcentage',
              value: 'percentage',
            }
          ],
          edition:false,
          form:{
            name:"",
            code:'',
            amount:0,
            promotion_type:'global',
            active:true,
            type:'percentage',
            free_shipping:false,
            use_count:0,
            end_date:null,
            minimum_spend:null,
            maximum_spend:null,
            individual_use:true,
            exclude_sale_items:false,
            products:null,
            product_categories:null,
            allowed_emails:null,
            usage_limit:null,
            user_limit:1
          },
      }
  },
  props: ['promotion', 'readOnly'],
  watch: { 
    promotion: function(newVal, oldVal) { // watch it
      console.log('type de promo 3')
      console.log(this.promotion.products)
      this.form.products=[]
      for(let j=0;j<this.promotion.products.length;j++)
      {
        console.log('ok')
        for(let i=0;i<this.productsChoices.length;i++)
        {
          if(this.promotion.products[j].id == this.productsChoices[i].value)
          {
            console.log('on la')
            this.form.products.push(this.productsChoices[i])
          }
        }
      }
        }
      },
 
  methods:{
    async getProducts() {
      let products = await APIRequest.list('glasses')
      this.products=products[0];
      this.productsChoices=Utils.formatSelect(this.products,'name','id')
     
    },
    async createPromotion()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
          
          let datas=this.form
          let products=null
          if(this.form.products)
          {
            if(this.form.products.length)
            {
              products=[]
              for (let i=0;i<this.form.products.length;i++)
              {
                let item={id:this.form.products[i].value}
                products.push(item)
              }

              datas.products=products
              
            }
            
          }
            
          
          await APIRequest.create('promotions',datas)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La promotion a bien été créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'promotions'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
   async editPromotion()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
          let datas={...this.form}
          delete datas.id
          let products=null
          if(this.form.products)
          {
            if(this.form.products.length)
            {
              products=[]
              for (let i=0;i<this.form.products.length;i++)
              {
                let item={id:this.form.products[i].value}
                products.push(item)
              }

              datas.products=products
              
            }
          }
          

          await APIRequest.update('promotions',this.promotion.id,datas)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La promotion a bien été modifiée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'promotions'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    
     
      
  },
  async mounted(){
    await this.getProducts()
    if(this.promotion!=null)
    {
      this.form=this.promotion
      this.file=this.promotion.photo
      this.edition=true
      console.log('type de promo 2')
      console.log(this.promotion.products.length)

      let products=[]
      for(let j=0;j<this.promotion.products.length;j++)
      {
        console.log('ok')
        for(let i=0;i<this.productsChoices.length;i++)
        {
          if(this.promotion.products[j].id == this.productsChoices[i].value)
          {
            console.log('on la')
            products.push(this.productsChoices[i])
          }
        }
      }
      this.form.products=products

    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>